import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from "rxjs";
import { v4 as uuid } from 'uuid';


@Injectable()
export class RequestIdInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    req = req.clone({
      setHeaders: {
        'X-Request-ID': uuid(),
      },
    });

    return next.handle(req);
  }
}

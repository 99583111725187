import { Component, OnInit, ViewChild } from '@angular/core';
import { IonRouterOutlet, LoadingController, ModalController } from '@ionic/angular';
import { AuthModalWebBaseComponent, QBasketService, UserOverview } from '@q-pass/user';
import { QAuthService, QLocaleService, QThemeService } from '@q-pass/core';
import { combineLatest, map, Observable, of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../environments/environment';
import { AppService } from './providers/app/app.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  @ViewChild(IonRouterOutlet, { static : true }) routerOutlet: IonRouterOutlet | undefined;

  public userOverview$: Observable<UserOverview | null> = of(null);
  public basketOverview$: Observable<{items: number; total: number} | null> = of(null);
  public supportedLanguages: Array<{code: string; desc: string}> = [];
  private loadingElem: HTMLIonLoadingElement | null = null;

  constructor(
    private modalController: ModalController,
    private loadingCtrl: LoadingController,
    private translateService: TranslateService,
    public localeService: QLocaleService,
    private themeService: QThemeService,
    public authService: QAuthService,
    private basketService: QBasketService,
    private appService: AppService
  ) {
    this.initializeApp();
  }

  ngOnInit() {

    this.userOverview$ = this.authService.userProfile$.pipe(
      map(prof => {
        if (prof) {
          let userInitialIcon = [prof.firstName || '', prof.lastName || '']
            .map(n => n[0]?.toUpperCase())
            .join("");

          return {displayName: prof.firstName, nameInitials: userInitialIcon, photoUrl: this.authService.currentUser.photoURL};
        }
        else return null;
      })
    );

    this.basketOverview$ = combineLatest([this.basketService.basketTotalItemsNumber, this.basketService.orderTotalCost]).pipe(
      map((res: [number, number]) => {
        return {items: res[0], total: res[1]};
      })
    );

    this.appService.displayAuthModal$.subscribe(val => {
      this.presentAuthModal();
    })

    this.supportedLanguages = this.localeService.supportedLanguages.map(val => {return {code: val.code, desc: val.shortName};});

  }

  initializeApp() {

    this.localeService.initLocale();
    // TODO: uncomment this part, if you want to support dark-mode
    // this.themeService.initTheme();

  }

  langClicked(val: {code: string}) {
    this.localeService.setLanguage(val.code);
  }

  async presentAuthModal() {
    const modal = await this.modalController.create({
      presentingElement: document.getElementById('web-app') || undefined,
      component: AuthModalWebBaseComponent,
      componentProps: {
        termsUrl: environment.QpassConfiguration.termsUrl + '?_locale=' + this.localeService.getSelectedLanguage(),
        privacyUrl: environment.QpassConfiguration.privacyUrl + '?_locale=' + this.localeService.getSelectedLanguage()
      },
    });

    await modal.present();
  }

}

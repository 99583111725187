import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UserDataService {

  currentToken = "";

  getUserToken(): string | null {
    return this.currentToken;
  }

  setCurrentToken(token: string) {
    this.currentToken = token;
  }
}
